import React, { useState, useEffect } from "react";
import SlickHomebanner from "./snippets/MainSlider"
import LoadingSpinner from "./snippets/LoadingSpinner"
import { Helmet } from "react-helmet";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>SG8 Casino Filipino - PAGCOR-Licensed Slots & Sports Betting</title>
            <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        </Helmet>
        {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
        <div className="main-wrapper">
          <div className="main-content-area">
            <SlickHomebanner/>
            <div className="featured-slots-container">
              <div className="slots-headlines-container">
                  <div className="h2-text-style">
                      <span>Hottest Slots</span> <h2>Spin Now!</h2>
                  </div>
                  <div className="slot-provider-list">
                      <img src="/images/redtiger.webp" alt=""/>
                      <img src="/images/rtg.webp" alt=""/>
                      <img src="/images/spade.webp" alt=""/>
                      <img src="/images/jili.webp" alt=""/>
                      <img src="/images/netent.webp" alt=""/>
                      <img src="/images/pg.webp" alt=""/>
                      <img src="/images/playson.webp" alt=""/>
                  </div>
                  <div className="c2a-wrapper">
                      <button className="yellow-btn">View More</button>
                  </div>
              </div>
              <div className="hottest-gamelist">
                  <div className="game-box"><img src="/images/59785.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                  <div className="game-box"><img src="/images/59785.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                  <div className="game-box"><img src="/images/59785.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                  <div className="game-box"><img src="/images/59785.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                  <div className="game-box"><img src="/images/59785.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                  <div className="game-box"><img src="/images/59785.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
              </div>
          </div>
          <div className="featured-slots-container">
              <div className="slots-headlines-container">
                  <div className="h2-text-style">
                      <span>Hot Live Casino</span> <h2>Bet Now!</h2>
                  </div>
                  <div className="ld-provider-list">
                      <img src="/images/evolution-official.webp" alt=""/>
                      <img src="/images/sa-gaming-official.webp" alt=""/>
                  </div>
                  <div className="c2a-wrapper">
                      <button className="yellow-btn">View More</button>
                  </div>
              </div>
              <div className="hottest-gamelist-ld">
                  <div className="game-box-ld"><img src="/images/59791.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                  <div className="game-box-ld"><img src="/images/59791.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                  <div className="game-box-ld"><img src="/images/59791.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                  <div className="game-box-ld"><img src="/images/59791.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                  <div className="game-box-ld"><img src="/images/59791.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
              </div>
          </div>
          <div className="featured-slots-container">
            <div className="service-selector">
                <div className="services-selector-box"><img src="/images/slot-selector.webp" alt=""/><span>Online Slot</span><a className="yellow-btn">Play Now</a></div>
                <div className="services-selector-box"><img src="/images/ld-selector.webp" alt=""/><span>Live Casino</span><a className="yellow-btn">Play Now</a></div>
                <div className="services-selector-box"><img src="/images/sprotsbook-selector.webp" alt=""/><span>Sportsbook</span><a className="yellow-btn">Bet Now</a></div>
            </div>
          </div>
          </div>
        </div>
        
        <div className="main-content-wrapper">
        <div className="text-content-area">
            <h2 className="text-content-title">Who is SG8 Casino?</h2>
            <p>Explore the vibrant essence of Philippines’ online casino at SG8 Casino – your meticulously crafted online gaming haven designed for ardent enthusiasts. Embark on an exhilarating journey into the world of online entertainment, promising a fusion of thrills, wins, and boundless excitement to captivate every player’s senses.</p>
            <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
            <p>At SG8 Casino, we revolutionize the online gaming landscape with a spectrum of meticulously curated options, catering to the discerning tastes of the Filipino gaming community. From captivating Online Slots to Live Casino extravaganzas and heart-racing Sports Betting, all fortified by PAGCOR’s seal of safety and unwavering reliability.</p>
            <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
            <p>Step into a journey in our Live Casino, powered by Evolution Gaming. Encounter the captivating suspense of Live Baccarat, the strategic depth of Blackjack, the spinning excitement of Live Roulette, and the dynamic entertainment of Crazy Time.</p>
            <div className="payment-menthod-container">
                <img src="/images/gcash-maya.webp" alt="" style={{width: `unset`}}/>
                <img src="/images/dragonpay.webp" alt="" style={{width: `unset`}}/>
                <img src="/images/fortunepay.webp" alt="" style={{width: `unset`}}/>
                <img src="/images/lazada.webp" alt="" style={{width: `unset`}}/>
            </div>
            <div></div>
        </div>
        </div>
        </div>
    )}
    </>
  )
}

export default App