import Login from '../api/Login';

function LoginModal() {
    return (
      <>
      <div className='modal-container'>
        <img className='formlogo' src='/images/SG8-logo.png' />
        <Login />
      </div>
      </>
    )
  }
export default LoginModal