import { Helmet } from "react-helmet";

function LiveCasino() {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Evolution Games | Ultimate Live Casino - SG8 Casino </title>
          <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
      </Helmet>
      <div className="main-wrapper desktop-view">
                <div className="ld-game-selector">
                    <div className="ld-game-box"><img src="/images/evolution-games.webp" alt="" onclick="launchGame('','36001',false,'en',0)"/><button className="yellow-btn">Play Now</button></div>
                    <div className="ld-game-box"><img src="/images/sa-gaming.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                    <div className="ld-game-box"><img src="/images/afb-gaming.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                </div>
      </div>
    </>
  )
}

export default LiveCasino