
import React, { useState } from 'react';
function Withdraw() {

    const [visibleId, setVisibleId] = useState('withdrawalFormPayCools'); // State to track which element should be visible
  
    // Function to handle click event on deposit links
    const handleWithdrawClick = (id) => {
      setVisibleId(id); // Update the state to show the corresponding element
    };

    return (
      <>
        <div className="account-container withdraw-container">
          <div className="page-headlines-container">
            <div className="page-h2-text-style">
              <h2>Withdrawal Request</h2><span>Get our Best Deals from SG8.Casino</span>
            </div>
          </div>
          <p className="remainingrollover">Remaining Rollover: <span id="remainingRollover-amt">...</span></p>
          <div className="withdraw-nav nav-tabs">
              <div id="payCoolsWithdrawalBtn" className={`${visibleId === 'withdrawalFormPayCools' ? 'active' : ''}`}><a onClick={() => handleWithdrawClick('withdrawalFormPayCools')}>eWallet (New)</a></div> 
              <div id="fortunePayWithdrawalBtn" className={`${visibleId === 'withdrawalFormFortunePay' ? 'active' : ''}`}><a onClick={() => handleWithdrawClick('withdrawalFormFortunePay')}>FortunePay</a></div>
              <div id="help2payBtn" className={`${visibleId === 'withdrawalFormHelp2Pay' ? 'active' : ''}`}><a onClick={() => handleWithdrawClick('withdrawalFormHelp2Pay')}>Internet Banking</a></div> 
          </div>
        
        {visibleId === 'withdrawalFormHelp2Pay' && <form id="withdrawalFormHelp2Pay"><input name="cmd" type="hidden" value="WithdrawRequestHelp2Pay" /><input name="u" type="hidden" value="" id="withdrawal-help2pay-username" />
                
            <div className="row">
                <div className="hLabel">Bank</div>
                  <div className="input-container"><select name="mbc" className="form-control">
                  <option value="">Select Bank</option>
                  <option value="GCASH">GCASH (GCASH)</option>
                  <option value="BDO">Banco de Oro (BDO)</option>
                  <option value="BDOE">Banco de Oro Corporate(BDOE)</option>
                  <option value="BPI">Bank of the Philippine Islands (BPI)</option>
                  <option value="LBP">Land Bank of the Philippines (LBP)</option>
                  <option value="MTB">MetroBank (MTB)</option>
                  <option value="PNB">Philippine National Bank (PNB)</option>
                  <option value="PNBe">Philippine National Bank Biz (PNBe)</option>
                  <option value="RCBC">Rizal Commercial Banking Corporation (RCBC)</option>
                  <option value="CBC">Chinabank (CBC)</option>
                  <option value="CBCe">Chinabank Biz (CBCe)</option>
                  <option value="SBC">Security Bank Corporation (SBC)</option>
                  <option value="SBCe">Security Bank Biz (SBCe)</option>
                  <option value="DBP">Development Bank of the Philippines (DBP)</option>
                  <option value="SLBA">Sterling Bank Asia (SLBA)</option>
                  <option value="EWB">Eastwest Bank (EWB)</option>
                  <option value="UBP">Union Bank of Philippines (UBP)</option>
                  <option value="SEABANK">SEABANK</option>
                  <option value="DCPAY">COINS.PH (DC PAY)</option>
                  <option value="MAYA">Maya Bank</option>
                  <option value="PAYMAYA">PayMaya Philippines</option>
                  <option value="RBC">Robinsons Bank Corporation</option>
                  <option value="AUB">Asia United Bank</option>
                  <option value="GRABPAY">GRABPAY</option>
                  <option value="GOTYME">GOTYME</option>
                  <option value="PSB">Philippine Savings Bank</option>
                  <option value="CIMBP">CIMB PH</option>
                </select>
                </div>
            </div>
            <div className="row">
                <div className="hLabel">Bank Account Name</div>
                <div className="input-container"><input className="form-control" title="" autoComplete="off" name="man" type="text" value="" placeholder="Bank Account Name" data-trigger="focus" data-toggle="popover" data-placement="bottom" data-content="" data-original-title="Bank Account Name" /></div>
            </div>
            <div className="row">
                <div className="hLabel">Bank Account Number</div>
                <div className="input-container"><input className="form-control" title="" autoComplete="off" name="mac" type="text" value="" placeholder="Bank Account Number" data-trigger="focus" data-toggle="popover" data-placement="bottom" data-content="" data-original-title="Bank Account Number" /></div>
            </div>
            <div className="row">
                <div className="hLabel">Balance</div>
                <div className="input-container"><div id="withdrawal-help2pay-balance" className="balance">...</div></div>
            </div>
            <div className="row">
                <div className="hLabel">Withdrawal Amount</div>
                <div className="input-container"><input id="withdrawal-help2pay-amount" className="form-control" autoComplete="off" max="1000000" maxLength="10" min="100" name="amt" required="required" type="text" placeholder="Min:100, Max:1,000,000" /></div>
            </div>
            <div className="row">
                <div className="input-container"  colSpan="2" style={{textAlign: "center", background: "transparent", borderBottom: "0px!important"}}>
                    <div id="withdrawal-help2pay-alert-cont" className="alert alert-info fade in alert-dismissable"><div id="withdrawal-help2pay-alert"></div></div>
                    <button id="btn-help2pay-submit" className="red-button" type="button">SUBMIT</button>
                </div>
            </div>
            </form>}
          
        {visibleId === 'withdrawalFormFortunePay' && <form id="withdrawalFormFortunePay"><input name="cmd" type="hidden" value="WithdrawRequestFortunePay" /><input name="u" type="hidden" value="" id="withdrawal-fortunepay-username" /><input name="mbc" type="hidden" value="FortunePay" /><input name="man" type="hidden" value="FortunePay" />
          
          <div className="row">
            <div className="hLabel">Phone Number</div>
            <div className="input-container"><input className="form-control" title="" autoComplete="off" maxLength="11" name="mac" required="required" type="text" value="" placeholder="Phone Number (eg: 09123456789)" data-trigger="focus" data-toggle="popover" data-placement="bottom" data-content="Registered phone number at FortunePay starting with [0]" data-original-title="Phone Number" /></div>
          </div>
          <div className="row">
            <div className="hLabel">Balance</div>
            <div className="input-container"><div id="withdrawal-fortunepay-balance" className="balance">...</div></div>
          </div>
          <div className="row">
            <div className="hLabel">Withdrawal Amount</div>
            <div className="input-container"><input id="withdrawal-fortunepay-amount" className="form-control" autoComplete="off" max="100000" maxLength="10" min="1" name="amt" required="required" type="text" placeholder="Min:100, Max:30,000" /></div>
          </div>
          <div className="row">
            <div className="input-container" colSpan="2" style={{textAlign: "center", background: "transparent", borderBottom: "0px!important"}}>
              <div id="withdrawal-fortunepay-alert-cont" className="alert alert-info fade in alert-dismissable"><div id="withdrawal-fortunepay-alert"></div></div>
              <button id="btn-fortunepay-submit" type="button" className="red-button">SUBMIT</button>
            </div>
          </div>
          
            </form>}

        {visibleId === 'withdrawalFormPayCools' && <form id="withdrawalFormPayCools" style={{display: "block"}}><input name="cmd" type="hidden" value="WithdrawRequestPayCools" /><input name="u" type="hidden" value="" id="withdrawal-paycools-username" />
                
            <div className="row">
              <div className="hLabel">Wallet Type</div>
              <div className="input-container"><select id="payCoolsWalletType" name="mbc" className="form-control">
              <option value="EWALLET">EWALLET</option>
              </select>
              </div>
            </div>
            <div className="row">
              <div className="hLabel" id="payCoolsWalletLabel">eWallet</div>
                <div className="input-container">
                  <select id="payCoolsWallet" name="man" className="form-control">
                    <option value="">Select eWallet</option>
                    <option value="GCASH">GCASH</option>
                    <option value="COINS">COINS</option>
                    <option value="PAYMAYA">PAYMAYA</option>
                    <option value="GRABPAY">GRABPAY</option>
                    <option value="SHOPEE">SHOPEE</option>
                    <option value="OMNIPAY">OMNIPAY</option>
                  </select>
                </div>
            </div>
            <div className="row">
                <div className="hLabel">Mobile Number</div>
                <div className="input-container"><input className="form-control" title="" autoComplete="off" name="mac" type="text" value="" placeholder="Mobile Number (eg: 09XXXXXXXXX)" data-trigger="focus" data-toggle="popover" data-placement="bottom" data-content="" data-original-title="Bank Account Number" /></div>
            </div>
            <div className="row">
                <div className="hLabel">Balance</div>
                <div className="input-container"><div id="withdrawal-paycools-balance" className="balance">...</div></div>
            </div>
            <div className="row">
                <div className="hLabel">Withdrawal Amount</div>
                <div className="input-container"><input id="withdrawal-paycools-amount" className="form-control" autoComplete="off" max="1000000" maxLength="10" min="100" name="amt" required="required" type="text" placeholder="Min:100, Max:50,000" /></div>
            </div>
            <div className="row">
                <div className="input-container"  colSpan="2" style={{textAlign: "center", background: "transparent", borderBottom: "0px!important"}}>
                <div id="withdrawal-paycools-alert-cont" className="alert alert-info fade in alert-dismissable"><div id="withdrawal-paycools-alert"></div></div>
                <button id="btn-paycools-submit" className="red-button" type="button">SUBMIT</button>
            </div>
            </div>
            </form>}
        </div>
    </>
      
  )
}

export default Withdraw