import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import Promotion from "./pages/Promotion";
import LiveCasino from "./pages/LiveCasino";
import Sports from "./pages/Sports";
import Dashboard from "./pages/Dashboard";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";
import RequestReport from "./pages/RequestReport";
import GameReport from "./pages/GameReport";
import Vip from "./pages/Vip";
import Rtg from "./pages/slots/RTG";
import Spadegaming from "./pages/slots/Spadegaming";
import Bng from "./pages/slots/Bng";
import Jili from "./pages/slots/Jili";
import Playson from "./pages/slots/Playson";
import Netent from "./pages/slots/Netent";
import Redtiger from "./pages/slots/Redtiger";
import Nextspin from "./pages/slots/Nextspin";
import Nlc from "./pages/slots/Nlc";
import Pgsoft from "./pages/slots/Pgsoft";
import Jdb from "./pages/slots/Jdb";
import Cq9 from "./pages/slots/Cq9";
import Playstar from "./pages/slots/Playstar";
import Wazdan from "./pages/slots/Wazdan";
import Btg from "./pages/slots/Btg";
import Tpg from "./pages/slots/Tpg";
import Fachai from "./pages/slots/Fachai";
import Ygg from "./pages/slots/Ygg";
import Elbet from "./pages/slots/Elbet";
import Habanero from "./pages/slots/Habanero";
import PromotionPage from "./pages/promotion/DepositBonus";
import PaydayBonus from "./pages/promotion/PaydayBonus";
import BirthdayBonus from "./pages/promotion/BirthdayBonus";
import ReferralBonus from "./pages/promotion/ReferralBonus";
import CashBackBonus from "./pages/promotion/CashBackBonus";
import PageNotFound from "./pages/PageNotFound";
import "./style/style.css";
import Header from "./Header";
import Footer from "./Footer";
import Logout from "./snippets/LogoutButton";


const routes = [
  { path: "/", element: <App /> },
  { path: "/promotion", element: <Promotion /> },
  { path: "/live-casino", element: <LiveCasino /> },
  { path: "/sportsbook", element: <Sports /> },
  { path: "account/dashboard", element: <Dashboard /> },
  { path: "account/deposit", element: <Deposit /> },
  { path: "account/withdraw", element: <Withdraw /> },
  { path: "account/request-report", element: <RequestReport /> },
  { path: "account/game-report", element: <GameReport /> },
  { path: "slots/spadegaming", element: <Spadegaming /> },
  { path: "slots/rtg", element: <Rtg /> },
  { path: "slots/bng", element: <Bng /> },
  { path: "slots/jili", element: <Jili /> },
  { path: "slots/playson", element: <Playson /> },
  { path: "slots/netent", element: <Netent /> },
  { path: "slots/redtiger", element: <Redtiger /> },
  { path: "slots/nextspin", element: <Nextspin /> },
  { path: "slots/nlc", element: <Nlc /> },
  { path: "slots/pgsoft", element: <Pgsoft /> },
  { path: "slots/jdb", element: <Jdb /> },
  { path: "slots/cq9", element: <Cq9 /> },
  { path: "slots/playstar", element: <Playstar /> },
  { path: "slots/wazdan", element: <Wazdan /> },
  { path: "slots/btg", element: <Btg /> },
  { path: "slots/tpg", element: <Tpg /> },
  { path: "slots/fachai", element: <Fachai /> },
  { path: "slots/ygg", element: <Ygg /> },
  { path: "slots/elbet", element: <Elbet /> },
  { path: "slots/habanero", element: <Habanero /> },
  { path: "/vip", element: <Vip /> },
  { path: "/promotion/depositbonus", element: <PromotionPage /> },
  { path: "/promotion/paydaybonus", element: <PaydayBonus /> },
  { path: "/promotion/birthdaybonus", element: <BirthdayBonus /> },
  { path: "/promotion/referralbonus", element: <ReferralBonus /> },
  { path: "/promotion/cashbackbonus", element: <CashBackBonus /> },
  { path: "*", element: <PageNotFound /> }
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Header />
    <div id="content" className="site-content">
      <div id="primary" className="content-area">
        <main id="main" className="site-main">
          <Logout />
          <Router>
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
          </Router>
        </main>
      </div>
    </div>
    <Footer />
  </>
);