import React, { useState, useEffect } from 'react';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    const savedUsername = localStorage.getItem('username');
    const rememberMeChecked = localStorage.getItem('rememberMe') === 'true';

    if (loggedInStatus && savedUsername) {
      setIsLoggedIn(true);
      setUsername(savedUsername);
    }

    setRememberMe(rememberMeChecked);
  }, []);

  const handleLogin = async () => {
    try {
      const response = await fetch("https://sg8.casino/json/wlp/sg8/json.jsp", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
          cmd: "loginjs",
          u: username,
          p: password
        })
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      handleResponse(data);
    } catch (error) {
      setErrorMessage("There was a problem with the login operation. Please try again.");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleResponse = (data) => {
    if (data.s === "success") {
      localStorage.setItem('isLoggedIn', 'true');
      if (rememberMe) {
        localStorage.setItem('username', data.username);
        localStorage.setItem('rememberMe', 'true');
      } else {
        localStorage.removeItem('username');
        localStorage.removeItem('rememberMe');
      }
      setUsername(data.username);
      setIsLoggedIn(true);
      setErrorMessage('');
      redirectToHomePage(); // Redirect to homepage after successful login
    } else {
      setErrorMessage(data.emsg || "Login failed. Please try again.");
      console.error("Login failed:", data);
    }
  };

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  // Function to redirect to homepage
  const redirectToHomePage = () => {
    // Your logic to redirect to the homepage
    // For example:
    window.location.href = '/'; // Redirect to the homepage URL
  };

  return (
    <div>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <div className='form-login'>
        <label>Username</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
        />
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <label>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMe}
          />
          Remember Me
        </label>
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
}

export default Login;