import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MainSlider = ({ sliderCurrentPage, bannerCurrentPage }) => {
  const slides = [
    { id: 'SPADEGAMING', src: '/images/spadegaming-hero.webp', alt: 'Spadegaming', description: 'Play Asia top online slot games on Spadegaming.', buttonText: 'Why Spadegaming?', href: '/slots/rtg', position: 'right' },
    { id: 'PLAYSTAR', src: '/images/playstar-hero.webp', alt: 'Playstar', description: 'Play Asia top online slot games on Spadegaming.', buttonText: 'Why Spadegaming?', href: '/slots/playstar', position: 'left' },
  ];

  const activeSlideIndex = slides.findIndex(slide => slide.id === bannerCurrentPage);

  // If there is an active slide, move it to the first position
  if (activeSlideIndex > 0) {
    const [activeSlide] = slides.splice(activeSlideIndex, 1);
    slides.unshift(activeSlide);
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderSlide = (slide, isActive) => {
    const Tag = isActive ? 'h1' : 'h2';
    return (
      <div key={slide.id}>
        <div className={`hero-banner-wrapper heroBannerWrapper-${slide.position}`} style={{ color: '#fff' }}>
          <Tag className={`headingTag ${isActive ? 'active-slide' : ''}`}>{slide.alt}</Tag>
          <p className='slide-description'>{slide.description}</p>
          <a href={slide.href} className='heroBtn'>{slide.buttonText}</a>
        </div>
        <img src={slide.src} alt={slide.alt} className='slide-image' />
      </div>
    );
  };

  return (
    <div className='sg8-hero-banner'>
      <Slider {...settings}>
        {slides.map((slide, index) => renderSlide(slide, index === 0))}
      </Slider>
    </div>
  );
};

export default MainSlider;
