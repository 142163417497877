import React, { useState, useEffect } from 'react';

function Register() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');

  useEffect(() => {
    const body = document.querySelector('body');
    if (isLoggedIn) {
      body.classList.add('isLoggedIn');
    } else {
      body.classList.remove('isLoggedIn');
    }
  }, [isLoggedIn]);

  const handleRegister = async () => {
    try {
      const response = await fetch("https://sg8.casino/json/wlp/sg8/json.jsp", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
          cmd: "register",
          ag: "SG8PMR",
          name1: firstName,
          name2: lastName,
          password: password,
          phone: phoneNumber,
          dob: dob,
          domain: "sg8.casino",
          from: "googleads" // Optional field
        })
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
  
      const data = await response.json();
      if (data.s === "s") {
        setErrorMessage('Registration successful:');
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true'); // Save isLoggedIn state in local storage
        localStorage.setItem('username', data.u); // Save the username in local storage
        window.location.href = "/"; // Redirect to home page
      } else {
        setErrorMessage(data.emsg || "Registration failed. Please try again.");
      }
    } catch (error) {
      setErrorMessage("There was a problem with the registration operation. Please try again.");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <div>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <div className='form-register'>
        <img className='formlogo' src='/images/SG8-logo.png' />
        <input type="hidden" name="cmd" value="register" />
        <input type="hidden" name="ag" value="SG8PMR" />
        <div className='formcol'>
          <div>
            <label>First Name</label>
            <input type="text" onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
          </div>
          <div>
            <label>Last Name</label>
            <input type="text" onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
          </div>
        </div>
        <label>Password</label>
        <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
        <div className='formcol'>
          <div>
            <label>Phone Number</label>
            <input type="tel" onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" />
          </div>
          <div>
            <label>Date of Birth</label>
            <input type="date" onChange={(e) => setDob(e.target.value)} placeholder="Date of Birth" />
          </div>
        </div>
        <button onClick={handleRegister}>Register</button>
      </div>
    </div>
  );
}

export default Register;